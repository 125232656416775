import { Layout } from "@/components/layout";
import { gql, useQuery } from "@apollo/client";
import { getApolloAuthClient, useAuth, useLogout } from "@faustwp/core";

function AuthenticatedView() {
  const client = getApolloAuthClient();
  const { logout } = useLogout();

  const { data, loading } = useQuery(
    gql`
      {
        viewer {
          name
        }
      }
    `,
    { client },
  );

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <Layout>
      <p>Welcome {data?.viewer?.name}!</p>
      <button onClick={() => logout()}>Logout</button>
    </Layout>
  );
}

export default function Page() {
  const { isAuthenticated, isReady, loginUrl } = useAuth({
    strategy: "redirect",
    shouldRedirect: false,
  });

  if (!isReady) {
    return <>Loading...</>;
  }

  if (isAuthenticated === true) {
    return <AuthenticatedView />;
  }

  // add /wp in front of the login url
  const newLoginUrl = loginUrl?.replace("/generate", "/wp/generate");

  return (
    <Layout>
      <p>Welcome!</p>
      <a href={newLoginUrl}>Login</a>
    </Layout>
  );
}
